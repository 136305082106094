<template>
  <GButton
    href="/browse"
    name="home-browse-all-gift-ideas-button"
    primary
    size="large"
    label="Browse All Gift Ideas"
    class="!bg-secondary-base !hover:bg-secondary-dark"
  />
</template>
