<script lang="ts">
import layout from '~/consumer/layouts/DefaultLayout.vue'
export default { layout }

</script>
<script lang="ts" setup>
import Hero from '@consumer/modules/home/Hero.vue'
import BrowseGiftIdeas from '@consumer/modules/home/BrowseGiftIdeas.vue'
import FindLocalGifts from '@consumer/modules/home/FindLocalGifts.vue'
import HowGiftlyWorks from '@consumer/modules/home/HowGiftlyWorks.vue'
import CustomerTestimonials from '@consumer/modules/home/CustomerTestimonials.vue'
import SEOPlaceLists from '@consumer/modules/shared/SEOPlaceLists.vue'
import type PlaceList from '@consumer/types/PlaceList'
import Corporate from '@consumer/modules/home/Corporate.vue'
import type { Component } from 'vue'

withDefaults(defineProps<{
  placeLists: PlaceList[]
}>(), {  })

const homepageSections = computed(() => {
  const sections: Array<{ component: Component; props?: any }> = [
    { component: Hero },
    { component: Corporate },
    { component: BrowseGiftIdeas },
    { component: FindLocalGifts },
    { component: HowGiftlyWorks },
    { component: CustomerTestimonials },
  ]

  if (__props.placeLists.length) {
    sections.push({ component: SEOPlaceLists, props: { placeLists: __props.placeLists } })
  }

  return sections
})
</script>

<template>
  <div data-qa="Home">
    <component
      :is="section.component"
      v-for="(section, index) in homepageSections"
      :key="index"
      v-bind="section.props"
      :class="index % 2 === 0 ? 'bg-white' : null"
    />
  </div>
</template>

<style scoped lang="scss">
@media (max-width: 1024px) {
  :deep(.g-button:not(.g-button-small)) {
    width: 100%;
  }
}
</style>
