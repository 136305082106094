<script lang="ts" setup>
import type PlaceList from '@consumer/types/PlaceList'

withDefaults(defineProps<{
  placeLists: Array<PlaceList>
}>(), {  })

const activeList = ref(__props.placeLists[0])
</script>

<template>
  <div>
    <GContainer class="pt-40px lg:pt-20px">
      <h2 class="text-">Featured Gifts</h2>
    </GContainer>

    <GContainer class="!px-0 !lg:px-64px" slideable>
      <ul class="flex items-center gap-20px justify-between my-32px flex-nowrap text-md lg:text-lg place-list-titles">
        <li v-for="list, i in placeLists" :key="i">
          <a
            :id="`places-${list.category}-title`"
            :class="[
              'cursor-pointer font-semibold flex-nowrap whitespace-nowrap pb-8px text-grey-700 hover:text-navy',
              { '!text-navy border-b-primary-base border-b-2': activeList.category === list.category }
            ]"
            @click="activeList = list"
          >
            {{ list.title }}
          </a>
        </li>
      </ul>
    </GContainer>

    <GContainer class="pb-80px" slideable>
      <div
        v-for="list, i in placeLists"
        v-show="activeList.category === list.category"
        :key="i"
        class="w-screen-lg max-w-896px flex flex-wrap gap-12px"
      >
        <GPill
          :is="`a`"
          v-for="link, j in list.links"
          :key="j"
          :href="link.url"
          class="
            !text-navy !border-grey-400 !bg-grey-200
            !hover:text-primary-dark !hover:border-primary-base !hover:bg-primary-pale"
        >
          {{ link.title }}
        </GPill>
      </div>
    </GContainer>
  </div>
</template>

<style lang="scss">
.place-list-titles {
  li:first-of-type {
    @apply pl-24px lg:pl-0;
  }

  li:last-of-type {
    @apply pr-24px lg:pl-0;
  }
}
</style>
