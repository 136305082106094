<script lang="ts" setup>
import homeFindLocalGiftsBg from '@consumer/assets/images/home-find-local-gifts-bg.png'

const homeFindLocalGiftsBgUrl = computed(() => `url(${homeFindLocalGiftsBg})`)

const citySearchTerm = ref('')

const citySearchForm = ref<HTMLFormElement>()

const CITIES: Array<{
  label: string
  value: string
  url: string
}> = [
  {
    label: 'Atlanta',
    value: 'Atlanta, GA',
    url: '/atlanta-ga-gift-cards',
  },
  {
    label: 'Austin',
    value: 'Austin, TX',
    url: '/austin-tx-gift-cards',
  },
  {
    label: 'Boston',
    value: 'Boston, MA',
    url: '/boston-ma-gift-cards',
  },
  {
    label: 'Chicago',
    value: 'Chicago, IL',
    url: '/chicago-il-gift-cards',
  },
  {
    label: 'Dallas',
    value: 'Dallas, TX',
    url: '/dallas-tx-gift-cards',
  },
  {
    label: 'Houston',
    value: 'Houston, TX',
    url: '/houston-tx-gift-cards',
  },
  {
    label: 'Las Vegas',
    value: 'Las Vegas, NV',
    url: '/las-vegas-nv-gift-cards',
  },
  {
    label: 'Los Angeles',
    value: 'Los Angeles, CA',
    url: '/los-angeles-ca-gift-cards',
  },
  {
    label: 'New York',
    value: 'New York, NY',
    url: '/new-york-ny-gift-cards',
  },
]

const selectCity = (cityName: string) => {
  citySearchTerm.value = cityName
  nextTick(() => citySearchForm.value?.submit())
}
</script>

<template>
  <div class="relative h-680px lg:h-850px">
    <div class="w-full absolute top-40px lg:top-80px">
      <GContainer class="h-376px lg:h-640px find-local-gifts-container"/>
    </div>

    <div class="w-full absolute top-200px lg:top-325px">
      <GContainer>
        <GCard rounded shadow class="w-full p-8 lg:p-20">
          <h2>
            <span class="text-primary-base">From Coast to Coast.</span><br>
            Find Local Gifts for Your Loved Ones Anywhere.
          </h2>

          <form
            ref="citySearchForm"
            class="mt-8 lg:mt-10 mb-4 lg:mb-6 flex flex-col lg:flex-row flex-nowrap items-center gap-4 lg:gap-6"
            action="/gift-cards"
            method="get"
          >
            <div class="flex-1 <lg:w-full">
              <GInputField
                v-model="citySearchTerm"
                placeholder="Enter The Recipient's City, State"
                class="w-full !h-16 font-semibold"
                iconRight="new-magnifying-glass"
                name="city"
              />
            </div>
            <GButton
              primary
              type="submit"
              size="medium"
              name="home-browse-local-gifts-search-button"
              label="Browse Local Gifts"
              class="!h-16 w-full lg:max-w-max"
            />
          </form>

          <div class="overflow-x-auto">
            <div class="flex flex-wrap gap-2 w-290px lg:w-full">
              <GPill
                :is="`a`"
                v-for="city, i in CITIES"
                :key="i"
                :href="city.url"
                :label="city.label"
                class="
                  lg:flex-grow !text-md bg-grey-200 text-grey-700 border-grey-700
                  !px:6 !lg:px-2 text-center
                  hover:bg-grey-200 hover:border-primary-base hover:text-primary-base
                "
                @click="selectCity(city.value)"
              />
            </div>
          </div>
        </GCard>
      </GContainer>
    </div>
  </div>
</template>

<style lang="scss">
.find-local-gifts-container {
  background: v-bind("homeFindLocalGiftsBgUrl") no-repeat;
  background-size: contain;

  .find-local-gifts-card {
    box-shadow: 10px 10px 40px rgb(0 0 0 / 5%);
  }
}
</style>
