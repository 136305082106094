<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'

const LEFT_COLUMN_ITEMS = [
  {
    label: 'Christmas',
    icon: 'new-christmas-decor',
    url: '/browse/christmas-gifts',
  },
  {
    label: 'Mother\'s Day',
    icon: 'new-flower',
    url: '/browse/mothers-day-gifts',
  },
  {
    label: 'Father\'s Day',
    icon: 'new-tie',
    url: '/browse/fathers-day-gifts',
  },
  {
    label: 'Spa & Relaxation',
    icon: 'new-lotus',
    url: '/spa-beauty-gift-cards',
  },
  {
    label: 'Nail Salons',
    icon: 'new-category-nail-salon',
    url: '/nail-salon-gift-cards',
  },
]

const RIGHT_COLUMN_ITEMS = [
  {
    label: 'Going Out',
    icon: 'new-martini-glass',
    url: '/going-out-gift-cards',
  },
  {
    label: 'Food & Beverages',
    icon: 'new-fork-and-knife',
    url: '/restaurant-gift-cards',
  },
  {
    label: 'Shopping & Clothes',
    icon: 'new-shopping-bag',
    url: '/shopping-gift-cards',
  },
  {
    label: 'Experience & Travel',
    icon: 'new-airplane-takeoff',
    url: '/travel-gift-cards',
  },
  {
    label: 'Outdoors',
    icon: 'new-mountains',
    url: '/outdoor-gift-cards',
  },
]
</script>

<template>
  <Swiper
    :slidesPerView="'auto'"
    :spaceBetween="0"
    :pagination="{
      clickable: true,
    }"
    :modules="[Pagination]"
    class="home-browse-ideas-swiper"
  >
    <SwiperSlide
      v-for="section, i in [LEFT_COLUMN_ITEMS, RIGHT_COLUMN_ITEMS]"
      :key="i"
    >
      <div class="space-y-3 pb-12 lg:p-0">
        <a
          v-for="idea, j in section"
          :key="j"
          :href="idea.url"
          class="
            flex items-center gap-4 py-4 px-6
            bg-white rounded-4px border-1 border-solid border-grey-400 hover:border-primary-base
            font-semibold text-grey-700 hover:text-primary-base hover:bg-primary-pale
          "
          style="width: 224px;"
        >
          <GIcon :name="idea.icon" size="20px" class="stroke-2"/>
          {{ idea.label }}
        </a>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<style lang="scss">
.home-browse-ideas-swiper {
  @apply w-screen lg:w-464px;

  @media screen and (min-width: 528px) {
    @apply w-464px;
  }

  .swiper-pagination {
    @media screen and (min-width: 528px) {
      @apply hidden;
    }
  }

  .swiper-slide:nth-child(1) {
    @apply pl-24px w-248px lg:px-0 lg:w-224px;

    @media screen and (min-width: 528px) {
      @apply pl-0 w-240px;
    }
  }

  .swiper-slide:nth-child(2) {
    @apply pl-16px pr-16px w-264px lg:w-224px;

    @media screen and (min-width: 528px) {
      @apply px-0;
    }
  }
}
</style>
